import React, { useState, useRef } from "react";
import { Modal } from "antd";
import { PiFileAudioThin } from "react-icons/pi";
import {
  AiOutlinePlayCircle,
  AiOutlineStop,
  AiOutlineDelete,
} from "react-icons/ai";
import { Container, ProductContainer, MainConatiner } from "./styles";
import { FaMusic, FaPlay } from "react-icons/fa";
import { TbPlayerStopFilled } from "react-icons/tb";

const VoiceMailSettingsModel = ({
  isModalOpen,
  handleCancel,
  handleOk,
  selectedFile,
  setSelectedFile,
  voiceMailTitle,
  setVoiceMailTitle,
  audioSrc,
  setAudioSrc,
  isPlaying,
  setIsPlaying,
  audioRef,
}) => {
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("audio/")) {
      setSelectedFile(file);
      const fileURL = URL.createObjectURL(file);
      setAudioSrc(fileURL);
      setIsPlaying(false);
    } else {
      alert("Please select a valid audio file.");
    }
  };

  const handleDivClick = () => {
    document.getElementById("file-input").click();
  };

  const toggleAudioPlayback = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio(audioSrc);
    }

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleDeleteAudio = () => {
    setSelectedFile(null);
    setAudioSrc(null);
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
  };

  const handleTitleChange = (event) => {
    setVoiceMailTitle(event.target.value);
  };

  return (
    <Modal
      closeIcon={true}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      open={isModalOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      mask={true}
      width={630}
      className="custom-model"
    >
      <MainConatiner>
        <Container>
          <div className="title-main">
            <p className="title">Add Voicemail</p>
          </div>

          <ProductContainer>
            <div className="product-items">
              <span className="label">Title</span>
              <input
                className="input"
                placeholder="Enter title here..."
                value={voiceMailTitle}
                onChange={handleTitleChange}
              />
            </div>
            {!audioSrc && (
              <div className="file-selector">
                <div onClick={handleDivClick}>
                  <PiFileAudioThin color="#4cbbcb" size={50} />
                  <span className="label">Upload Audio file</span>
                </div>
              </div>
            )}

            <input
              id="file-input"
              type="file"
              accept="audio/*"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />

            {audioSrc && (
              <div className="audio-file-display">
                <div className="audio-icon">
                  <FaMusic color="#4cbbcb" size={15} />
                </div>
                <div className="audio-details">
                  <span className="audio-filename">{selectedFile?.name}</span>
                </div>
                <div className="audio-controls">
                  {isPlaying ? (
                    <TbPlayerStopFilled
                      color="#55637366"
                      size={20}
                      onClick={toggleAudioPlayback}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <FaPlay
                      color="#55637366"
                      size={16}
                      onClick={toggleAudioPlayback}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  <AiOutlineDelete
                    color="#55637366"
                    size={20}
                    onClick={handleDeleteAudio}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            )}
          </ProductContainer>
        </Container>

        <div className="button-div">
          <button
            className="cancel"
            type="button"
            onClick={() => {
              handleDeleteAudio();
              handleCancel();
            }}
          >
            Close
          </button>
          <button
            type="button"
            onClick={() => {
              handleDeleteAudio();
              handleOk();
            }}
          >
            Save
          </button>
        </div>
      </MainConatiner>
    </Modal>
  );
};

export default VoiceMailSettingsModel;
