import React, { useState } from "react";
import { Form, Modal,Input } from "antd";
import { RxCross2 } from "react-icons/rx";
import { Container, MainConatiner } from "./styles";
import { useSelector } from "react-redux";

const CreateSpace = ({ isCreateSpaceModelOpen, handleCancel, handleOk }) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { auth } = useSelector((state) => state);

  return (
    <Modal
      closeIcon={<RxCross2 />}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      open={isCreateSpaceModelOpen}
      onCancel={handleCancel}
      mask={true}
      width={630}
      className="custom-model"
    >
      <Form className="main-form" form={form} requiredMark={false}>
        <MainConatiner>
          <Container>
            <div className="title-main">
              <span className="title">Create a space</span>
            </div>

            <div className="main-div">
              {/* <span>Hello</span> */}
              <div className="form-container">
                <div className="welcome-message">
                  <Form.Item
                    label="Space Name"
                    name="description"
                    className="form-items"
                  >
                    <Input className="input" placeholder="Space name here..." />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Container>

          <div className="button-div">
            <button
              className="close-button"
              type="button"
              onClick={handleCancel}
              disabled={isSaving}
            >
              Close
            </button>
            <button
              type="button"
              // onClick={handleSave}
              disabled={isSaving}
              className={isSaving ? "saving" : ""}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </MainConatiner>
      </Form>
    </Modal>
  );
};

export default CreateSpace;
