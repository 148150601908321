import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  width: 100%;

  .members-title {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px;
    margin: 0px;
  }

  .members-number {
    color: var(--Gray-100, #556373);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .search-container {
    margin-top: 15px;
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

    .search-div {
      height: 41px;
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 0 15px;
      border: 1px solid #e3eced;
      border-radius: 30px;
      input {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
      }
    }
  }
`;

export { MainContainer };
