import React, { useEffect, useState, useRef } from "react";
import { ChatMessageContainer, AttachmentList, AttachmentItem } from "./styles";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { FiMoreVertical } from "react-icons/fi"; // Importing the three dots icon
import { useSelector } from "react-redux";
import Avatar from "./avatar/Avatarr";
import { InstanceWithAuth } from "../../../../../App";

function Comment({ note, selectedEmailId, refetchNotes, onEdit }) {
  const [expanded, setExpanded] = useState(false);
  const [showFaded, setShowFaded] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility
  const dropdownRef = useRef(null);
  const { auth } = useSelector((state) => state);

  useEffect(() => {
    if (note?.notes?.length > 350 && !expanded) {
      setShowFaded(true);
    } else {
      setShowFaded(false);
    }
  }, [expanded, note?.notes?.length]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleEditNote = () => {
    setShowDropdown(false);
    onEdit(note);
  };

  const handleDeleteNote = async () => {
    setShowDropdown(false);
    try {
      const response = await InstanceWithAuth.delete(
        `support/emails/notes/${note.id}`,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        refetchNotes();
      }
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const TruncateText = ({ text }) => {
    const maxLength = 15;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  return (
    <ChatMessageContainer expanded={expanded} showFaded={showFaded}>
      <div className="email-item">
        <div className="email-header">
          <Avatar currentEmployeeImage={note?.user} />

          <div className="email-details">
            <div className="email-top-row">
              <div className="left-container">
                <span className="email-sender">{note?.user?.first_name}</span>
              </div>

              <div className="make-row" ref={dropdownRef}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={handleDropdownToggle}
                >
                  <FiMoreVertical />
                </span>
                {showDropdown && (
                  <div className="dropdown-menu">
                    <div onClick={handleEditNote}>Edit Note</div>
                    <div onClick={() => handleDeleteNote(note.id)}>
                      Delete Note
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              className={`email-subject ${expanded ? "expanded" : ""}`}
              dangerouslySetInnerHTML={{ __html: note?.notes }}
            />

            <AttachmentList>
              {note?.attachments.map((file, index) => (
                <AttachmentItem key={index}>
                  <TruncateText text={file.filename}></TruncateText>
                  <span className="file-size">{file.formatted_size}</span>
                </AttachmentItem>
              ))}
            </AttachmentList>

            {note?.notes?.length > 150 && (
              <div className="toggle-button">
                <div className="show-more" onClick={toggleExpand}>
                  {expanded ? (
                    <>
                      Show less{" "}
                      <span
                        style={{
                          paddingTop: "4px",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TiArrowSortedUp />
                      </span>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                      }}
                    >
                      <span> Show more </span>
                      <span
                        style={{
                          padding: 0,
                          marginBottom: "-7px",
                        }}
                      >
                        <TiArrowSortedDown />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ChatMessageContainer>
  );
}

export default Comment;
