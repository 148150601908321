import React, { useState, useRef, useEffect } from 'react'
import { MainContainer } from './styles'
import NameAvatar from '../../common/NameAvatar'
import { HiOutlineX } from 'react-icons/hi' // Import CSS for styling
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
const CustomDropdown = ({
  options,
  onSelect,
  auth,
  hanlefetchMailBoxData,
  setEventType,
  setCurentPage,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState('Event')
  const dropdownRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const TruncateText = ({ text }) => {
    const maxLength = 15
    let truncatedText = text.substring(0, maxLength)
    if (truncatedText.length < text.length) {
      truncatedText += '...'
    }
    return <span title={text}>{truncatedText}</span>
  }
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option) => {
    setCurentPage(1)
    setSelectedOption(option?.name)
    onSelect(option?.key)
    setEventType(option?.key)
    setIsOpen(false)
  }

  const handleClearDropDown = (e) => {
    e.stopPropagation()
    setSelectedOption('Event')
    onSelect()
    setEventType('')
    // hanlefetchMailBoxData()
    setCurentPage(1)
  }
  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="placeholder">
            <TruncateText
              text={selectedOption ? selectedOption : ''}
            ></TruncateText>
          </div>
          {selectedOption !== 'Event' ? (
            <span onClick={handleClearDropDown} className="cancel-btn">
              x
            </span>
          ) : (
            ''
          )}
          {isOpen ? (
            <MdOutlineKeyboardArrowDown fill="rgb(76, 187, 203)"></MdOutlineKeyboardArrowDown>
          ) : (
            <MdOutlineKeyboardArrowDown></MdOutlineKeyboardArrowDown>
          )}
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            {options &&
              options?.map((option, index) => (
                <li key={index} onClick={() => handleOptionSelect(option)}>
                  {option?.name}
                </li>
              ))}
          </ul>
        )}
      </div>
    </MainContainer>
  )
}

export default CustomDropdown
