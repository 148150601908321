import React, { useState, useEffect } from 'react'
import { FirstColumnContainer } from './styles'
import { LuMessageSquare } from 'react-icons/lu'
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io'
import ChatItem from './chatItem/ChatItem'
import { CiSquarePlus } from 'react-icons/ci'
import CreateSpace from './createSpace/CreateSpace'

const FirstColumn = ({}) => {
  const [directMessageOpen, setDepartmentMessageOpen] = useState(true)
  const [spacesOpen, setSpacesOpen] = useState(false)
  const [isCreateSpaceModelOpen, setCreateSpaceModelOpen] = useState(false)

  const handleOk = () => {
    setCreateSpaceModelOpen(false)
  }
  const handleCancel = () => {
    setCreateSpaceModelOpen(false)
  }

  const showModal = () => {
    setCreateSpaceModelOpen(true)
  }

  return (
    <FirstColumnContainer>
      <div className="button">
        <LuMessageSquare size={18} />
        <span>New Chat</span>
      </div>

      <div className="bottom-section">
        <div
          style={{ flex: directMessageOpen && '1' }}
          className="most-outer-div"
        >
          <div className="header-div">
            <span
              className="icon"
              onClick={() => setDepartmentMessageOpen(!directMessageOpen)}
            >
              {directMessageOpen ? (
                <IoMdArrowDropdown size={16} />
              ) : (
                <IoMdArrowDropright size={16} />
              )}
            </span>
            <span className="header-label">Direct messages</span>
          </div>
          {directMessageOpen &&
            [1, 2, 3, 4, 5].map((chat) => (
              <div className="list-container">
                <ChatItem />{' '}
              </div>
            ))}
        </div>

        <div
          className="most-outer-div-spaces"
          style={{ flex: spacesOpen && '1' }}
        >
          <div className="header-div">
            <div className="left">
              <span className="icon" onClick={() => setSpacesOpen(!spacesOpen)}>
                {spacesOpen ? (
                  <IoMdArrowDropdown size={16} />
                ) : (
                  <IoMdArrowDropright size={16} />
                )}
              </span>
              <span className="header-label">Spaces</span>
            </div>
            <span className="icon" onClick={showModal}>
              <CiSquarePlus size={20} color="#4cbbcb" />
            </span>
          </div>
          {spacesOpen &&
            [1, 2, 3, 4].map((chat) => (
              <div className="list-container">
                <ChatItem />
              </div>
            ))}
        </div>
      </div>

      <CreateSpace
        isCreateSpaceModelOpen={isCreateSpaceModelOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
    </FirstColumnContainer>
  )
}

export default FirstColumn
