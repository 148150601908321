import React, { useState, useEffect, useRef } from 'react'
import { MainContainer, TabContainer, TabButton, TabContent } from './styles'
import CommonHaeder from '../../components/header/commonHeader/CommonHaeder'

import { Drawer, Tabs } from 'antd'
import AssociateContact from '../../components/support/AssociateContact/AssociateContact'
import { CloseOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import FirstColumn from '../../components/chatWithManager/FirstColumn/FirstColumn'
import SecondColumn from '../../components/chatWithManager/secondColumn/SecondColumn'

const ChatManager = () => {
  return (
    <div style={{ position: 'relative' }}>
      <CommonHaeder
        firstBcItem={'Chat with manager'}
        firstBcIcon={'supportChat'}
      />
      <MainContainer>
        <div className="first-column">
          <FirstColumn />
        </div>

        <div className="second-column">
          <SecondColumn />
        </div>
      </MainContainer>
    </div>
  )
}

export default ChatManager
