import React, { useState, useRef } from "react";
import { MainContainer } from "./styles";
import {
  MdOutlinePersonAddAlt1,
  MdInfoOutline,
  MdBlock,
  MdExitToApp,
  MdDeleteOutline,
} from "react-icons/md"; // Import relevant icons
import { BsPersonGear } from "react-icons/bs";
import AddedSpaceMembers from "./addedSpaceMembers/AddedSpaceMembers";
import InvitedSpaceMembers from "./invitedSpaceMembers/InvitedSpaceMembers";

const ManageMembers = () => {
  const [membersClicked, setmembersClicked] = useState(true);
  const [invitedClicked, setInvitedClicked] = useState(false);

  return (
    <MainContainer>
      <div className="left">
        <div className="item-div">
          <BsPersonGear color="#2b3746" size={17} />
          <span>Manage members</span>
        </div>
        <div
          className="tab-div"
          onClick={() => {
            setmembersClicked(true);
            setInvitedClicked(false);
          }}
        >
          <span
            style={{
              backgroundColor: membersClicked
                ? "var(--Blue-100, #4cbbcb)"
                : "#f5f8f9",
            }}
            className="line"
          ></span>
          <span
            style={{
              color: membersClicked && "var(--Blue-100, #4cbbcb)",
            }}
            className="text"
          >
            Added
          </span>
        </div>
        <div
          className="tab-div"
          onClick={() => {
            setmembersClicked(false);
            setInvitedClicked(true);
          }}
        >
          <span
            style={{
              backgroundColor: invitedClicked
                ? "var(--Blue-100, #4cbbcb)"
                : "#f5f8f9",
            }}
            className="line"
          ></span>
          <span
            style={{
              color: invitedClicked && "var(--Blue-100, #4cbbcb)",
            }}
            className="text"
          >
            Invited
          </span>
        </div>
      </div>
      <div className="right">
        {membersClicked ? <AddedSpaceMembers /> : <InvitedSpaceMembers />}
      </div>
    </MainContainer>
  );
};

export default ManageMembers;
