import React, { useEffect, useState } from "react";
import { ListWrapper } from "./styles";
import ChatCard from "./chatCard/ChatCard";

const ContractedChats = () => {
  const [emailIds, setEmailIds] = useState([]);

  // Fetch email IDs from localStorage on mount
  useEffect(() => {
    const storedEmailIds =
      JSON.parse(localStorage.getItem("emailIdsArray")) || [];
    setEmailIds(storedEmailIds);
  }, []);

  // Listen for custom 'localStorageChange' event to update the email list
  useEffect(() => {
    const handleStorageChange = () => {
      const storedEmailIds =
        JSON.parse(localStorage.getItem("emailIdsArray")) || [];
      setEmailIds(storedEmailIds);
    };

    // Attach the event listener
    window.addEventListener("localStorageChange", handleStorageChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("localStorageChange", handleStorageChange);
    };
  }, []);

  // Function to remove an email ID from localStorage and state
  const removeEmailFromList = (emailId) => {
    const updatedEmailIds = emailIds.filter((id) => id !== emailId);
    setEmailIds(updatedEmailIds); // Update state
    localStorage.setItem("emailIdsArray", JSON.stringify(updatedEmailIds)); // Update localStorage
  };

  return (
    <ListWrapper>
      {emailIds.length > 0
        ? emailIds.map((emailId) => (
            <ChatCard
              key={emailId}
              emailId={emailId}
              removeEmailFromList={removeEmailFromList}
            />
          ))
        : null}
    </ListWrapper>
  );
};

export default ContractedChats;
