import styled from "styled-components";

const ListWrapper = styled.div`
  width: auto;
  height: auto;
  max-height: 500px;
  background: transparent;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  z-index: 1000;
  gap: 10px;
  padding: 20px 20px 0 20px;
  ${'' /* overflow-y: auto; */}
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export { ListWrapper };
