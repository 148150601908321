import styled from 'styled-components'

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  .first-column {
    height: 100%;
    ${'' /* flex: 2; */}
    padding-top: 40px;
    min-width: 290px;
  }
  .second-column {
    height: 100%;
    flex: 1;
    padding-top: 40px;
    box-sizing: border-box;
  }
`

export { MainContainer }
