import styled from "styled-components";

const SecondColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${"" /* background: lightPink; */}

  .header {
    display: flex;
    background: white;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    border-bottom: 1px solid #e3eced;
    .left {
      display: flex;
      align-items: center;
      gap: 15px;
      .img {
        height: 30px;
        width: 30px;
        border-radius: 30px;
        overflow: hidden;
        img {
          height: 30px;
          width: 30px;
        }
      }
      .name-div {
        display: flex;
        flex-direction: column;

        .members {
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: var(--Gray-100, #556373);
        }
      }
    }
  }
`;

export { SecondColumnContainer };
