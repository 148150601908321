import React, { useState, useRef } from "react";
import { MainContainer } from "./styles";
import {
  MdOutlinePersonAddAlt1,
  MdInfoOutline,
  MdBlock,
  MdExitToApp,
  MdDeleteOutline,
} from "react-icons/md"; // Import relevant icons
import { BsPersonGear } from "react-icons/bs";

const SpaceOptionsDropDown = ({ setManageMembersClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">Space Name</div>
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#4CBBCB"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#556373"
                strokeOpacity="0.2"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
        {isOpen && (
          <div className="dropdown-list">
            {/* Manage members icon */}
            <div
              className="item-div"
              onClick={() => {
                setIsOpen(!isOpen);
                setManageMembersClick(true);
              }}
            >
              <BsPersonGear size={15} color="#556373" />
              <span className="dropdown-item">Manage members</span>
            </div>

            {/* Space details icon */}
            <div
              className="item-div"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <MdInfoOutline size={15} color="#556373" />
              <span className="dropdown-item">Space details</span>
            </div>

            {/* Block & Report icon */}
            <div
              className="item-div"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <MdBlock size={15} color="#556373" />
              <span className="dropdown-item">Block & Report</span>
            </div>

            {/* Leave icon */}
            <div
              className="item-div"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <MdExitToApp size={15} color="#556373" />
              <span className="dropdown-item">Leave</span>
            </div>

            {/* Delete icon */}
            <div
              className="item-div"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <MdDeleteOutline size={15} color="#556373" />
              <span className="dropdown-item">Delete</span>
            </div>
          </div>
        )}
      </div>
    </MainContainer>
  );
};

export default SpaceOptionsDropDown;
