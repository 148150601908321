import React, { useState, useEffect } from "react";
import { SecondColumnContainer } from "./styles";
import { MdPhotoSizeSelectSmall } from "react-icons/md";
import SpaceOptionsDropDown from "./spaceOptionsDropDown/SpaceOptionsDropDownn";
import ManageMembers from "./manageMembers/ManageMembers";
import { IoMdArrowBack } from "react-icons/io";

const SecondColumn = ({}) => {
  const [manageMembersClick, setManageMembersClick] = useState(false);

  return (
    <SecondColumnContainer>
      <div className="header">
        <div className="left">
          {manageMembersClick && (
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => setManageMembersClick(false)}
            >
              <IoMdArrowBack size={20} color="#556373" />
            </span>
          )}
          <span className="img">
            <img
              src="https://lh3.googleusercontent.com/a-/ALV-UjUNb8EWts7ArSfQ-g4COjtPHx5XB_oxfmngO-3-QApeMZCoNw=s40-w40-h40-c-k-no"
              alt=""
            />
          </span>
          <div className="name-div">
            <SpaceOptionsDropDown
              setManageMembersClick={setManageMembersClick}
            />
            <span className="members">3 members</span>
          </div>
        </div>
        <div className="right">
          <span className="icon">
            <MdPhotoSizeSelectSmall size={20} color="#2b3746" />
          </span>
        </div>
      </div>
      {manageMembersClick ? <ManageMembers /> : null}
    </SecondColumnContainer>
  );
};

export default SecondColumn;
