import styled from "styled-components";

const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  .title-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    align-self: stretch;

    .title {
      margin: 0;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.63px;
    }
  }
`;

const ProductContainer = styled.div`
  display: flex;
  padding: 0px 0px 24px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  height: auto;

  .add-products {
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .product-title {
      display: flex;
      width: 242.5px;
      align-items: center;
      gap: 27px;
      width: 242.5px;
      max-width: 100%;

      .title {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        line-height: 120%; /* 16.8px */
      }
    }
    .add-prduct-btn {
      display: flex;
      padding: 6px 12px;
      align-items: center;
      gap: 12px;
      height: 28px;
      border-radius: 4px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;

      .btn-icon {
        svg {
          width: 16px;
          height: 16px;
          fill: #4cbbcb;
          color: #4cbbcb;
        }
      }

      .btn-text {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
    }
  }

  .product-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    height: auto;

    .label {
      height: unset;
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .input {
      border-radius: 4px;
      border: 1px solid #d0dee1;
      height: auto;
      min-height: 42px;
      padding-left: 15px !important;
      width: 100%;
      outline: none;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .file-selector {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      flex-direction: column;
      gap: 15px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      .lebel {
        height: unset;
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }
    div:hover {
      background: var(--Blue-20, rgba(76, 187, 203, 0.2));
    }
  }

  .audio-file-display {
    display: flex;
    align-items: center;
    background-color: #f9fbfc;
    border: 1px solid #e5e8eb;
    border-radius: 5px;
    padding: 8px;
    margin-top: 10px;
    width: 100%;
    height: 41px;
  }

  .audio-icon {
    margin-right: 10px;
  }

  .audio-details {
    flex-grow: 1;
  }

  .audio-filename {
    font-size: 14px;
    color: #333;
  }

  .audio-controls {
    display: flex;
    align-items: center;
  }

  .audio-controls svg {
    margin-left: 25px;
  }
`;

const MainConatiner = styled.div`
  padding-right: 20px;

  .button-div {
    display: flex;
    justify-content: end;
    margin-top: 9px;
    gap: 15px;
    button {
      display: flex;
      height: 32px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      gap: 8px;
      border-radius: 3px;
      background: var(--Blue-100, #4cbbcb);
      cursor: pointer;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .cancel {
      display: flex;
      height: 32px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      gap: 8px;
      border-radius: 3px;
      cursor: pointer;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      border: 1px solid #d0dee1;
      background: #fff;
    }
  }
`;

export { Container, ProductContainer, MainConatiner };
