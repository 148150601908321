import React, { useState, useRef } from "react";
import { MainContainer } from "./styles";
import { IoSearch } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";

const AddedSpaceMembers = () => {
  return (
    <MainContainer>
      <span className="members-title">Members</span>
      <span className="members-number">2 members</span>
      <div className="search-container">
        <div className="search-div">
          <IoSearch />
          <input placeholder="Search members" />
        </div>
        <div>
          <FaPlus />
          <span>Add</span>
        </div>
      </div>
    </MainContainer>
  );
};

export default AddedSpaceMembers;
