import React from 'react'

import { ReactComponent as Location } from '../../assets/icons/location.svg'
import { ReactComponent as PlainsAndProducts } from '../../assets/icons/plains&products.svg'
import { ReactComponent as User } from '../../assets/icons/userIcon.svg'
import { ReactComponent as Empolyees } from '../../assets/icons/empolyees.svg'
import { ReactComponent as Billing } from '../../assets/icons/billing.svg'
import { ReactComponent as Support } from '../../assets/icons/support.svg'
import { ReactComponent as Mailbox } from '../../assets/icons/mailbox.svg'
import { ReactComponent as Setting } from '../../assets/icons/settings.svg'
import { ReactComponent as LiveAnswering } from '../../assets/icons/LiveAnswering.svg'
import { ReactComponent as Chat } from '../../assets/icons/LiveAnswering.svg'
import { ReactComponent as Companies } from '../../assets/icons/companies.svg'
import { ReactComponent as Search } from '../../assets/icons/search.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg'
import { ReactComponent as EmployeesBcIcon } from '../../assets/icons/empolyessBcIcon.svg'
import { ReactComponent as Check } from '../../assets/icons/check.svg'
import { ReactComponent as Remove } from '../../assets/icons/remove.svg'
import { ReactComponent as Person } from '../../assets/icons/person.svg'
import { ReactComponent as Box } from '../../assets/icons/box.svg'
import { ReactComponent as CallOperater } from '../../assets/icons/callOperater.svg'
import { ReactComponent as Circle } from '../../assets/icons/circle.svg'
import { ReactComponent as Eye } from '../../assets/icons/eye.svg'
import { ReactComponent as RightArrow } from '../../assets/icons/rightArrow.svg'
import { ReactComponent as Admin } from '../../assets/icons/admin.svg'
import { ReactComponent as MailOperater } from '../../assets/icons/mailOperator.svg'
import { ReactComponent as Manager } from '../../assets/icons/maneger.svg'
import { ReactComponent as Trash } from '../../assets/icons/trash.svg'
import { ReactComponent as Logout } from '../../assets/icons/logout.svg'
import { ReactComponent as File } from '../../assets/icons/file.svg'
import { ReactComponent as Copy } from '../../assets/icons/copy.svg'
import { ReactComponent as LocationBcIcon } from '../../assets/icons/locationBcIcon.svg'
import { ReactComponent as AddEmployee } from '../../assets/icons/addEmployee.svg'
import { ReactComponent as FillClose } from '../../assets/icons/fillCloseIcon.svg'
import { ReactComponent as FillAdd } from '../../assets/icons/fillAddIcon.svg'
import { ReactComponent as UserMail } from '../../assets/icons/userMail.svg'
import { ReactComponent as UserCall } from '../../assets/icons/userCall.svg'
import { ReactComponent as UserHash } from '../../assets/icons/userHash.svg'
import { ReactComponent as UserCompany } from '../../assets/icons/userCompany.svg'
import { ReactComponent as UserVoice } from '../../assets/icons/userVoice.svg'
import { ReactComponent as UserSetting } from '../../assets/icons/userSettingIcon.svg'
import { ReactComponent as Edit } from '../../assets/icons/edit.svg'
import { ReactComponent as Download } from '../../assets/icons/download.svg'
import { ReactComponent as CopyTwo } from '../../assets/icons/copyTwo.svg'
import { ReactComponent as Delete } from '../../assets/icons/delete.svg'
import { ReactComponent as CopyThree } from '../../assets/icons/copyThree.svg'
import { ReactComponent as AddIcon } from '../../assets/icons/AddIcon.svg'
import { ReactComponent as AmericanFlag } from '../../assets/icons/americanFlag.svg'
import { ReactComponent as UserUnFilled } from '../../assets/icons/userUnFilled.svg'
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg'
import { ReactComponent as MangerLogo } from '../../assets/icons/locationManeger.svg'
import { ReactComponent as MailBoxOperator } from '../../assets/icons/mailBoxOperatorLogo.svg'
import { ReactComponent as AdminLogo } from '../../assets/icons/adminLogo.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/editIcon.svg'
import { ReactComponent as PlanTitleIcon } from '../../assets/icons/planTitleIcon.svg'
import { ReactComponent as OCheck } from '../../assets/icons/o-check.svg'
import { ReactComponent as PhoneCall } from '../../assets/icons/phone-call.svg'
import { ReactComponent as MicrophoneOff } from '../../assets/icons/microphone-off.svg'
import { ReactComponent as CircleFilled } from '../../assets/icons/circle-filled.svg'
import { ReactComponent as HorizontalDotts } from '../../assets/icons/horizontal-dots.svg'
import { ReactComponent as Reports } from '../../assets/icons/reports.svg'
import { ReactComponent as SupportChat } from '../../assets/icons/chat.svg'
import { ReactComponent as LogTickets } from '../../assets/icons/logTicket.svg'
import { ReactComponent as ScanMail } from '../../assets/icons/scanMail.svg'
import { ReactComponent as ForwardMail } from '../../assets/icons/forwardMail.svg'
import { ReactComponent as SherdMail } from '../../assets/icons/sherdMail.svg'
import { ReactComponent as PickUpMail } from '../../assets/icons/PickUpMail.svg'
import { ReactComponent as JunckMail } from '../../assets/icons/junckRequestMail.svg'
import { ReactComponent as DepositMail } from '../../assets/icons/depositMail.svg'
import { ReactComponent as BackIcon } from '../../assets/icons/BackIcon.svg'
const GetLogo = (type) => {
  switch (type) {
    case 'location':
      return <Location />
    case 'plains and products':
      return <PlainsAndProducts />
    case 'user':
      return <User />
    case 'billing':
      return <Billing />
    case 'support':
      return <Support />
    case 'mailbox':
      return <Mailbox />
    case 'setting':
      return <Setting />
    case 'live answering':
      return <LiveAnswering />
    case 'employees':
      return <Empolyees />
    case 'companies':
      return <Companies />
    case 'chat':
      return <Chat />
    case 'search':
      return <Search />
    case 'searchIcon':
      return <SearchIcon />
    case 'employeesBcIcon':
      return <EmployeesBcIcon />
    case 'check':
      return <Check />
    case 'remove':
      return <Remove />
    case 'person':
      return <Person />
    case 'box':
      return <Box />
    case 'call-operater':
      return <CallOperater />
    case 'circle':
      return <Circle />
    case 'eye':
      return <Eye />
    case 'arrow':
      return <RightArrow />
    case 'admin':
      return <Admin />
    case 'manegar':
      return <Manager />
    case 'mailOperator':
      return <MailOperater />
    case 'trash':
      return <Trash />
    case 'mailOperator':
      return <MailOperater />
    case 'logout':
      return <Logout />
    case 'file':
      return <File />
    case 'copy':
      return <Copy />
    case 'locationBc':
      return <LocationBcIcon />
    case 'addEmployee':
      return <AddEmployee />
    case 'fillClose':
      return <FillClose />
    case 'fillAdd':
      return <FillAdd />
    case 'userMail':
      return <UserMail />
    case 'userCall':
      return <UserCall />
    case 'userHash':
      return <UserHash />
    case 'userCompany':
      return <UserCompany />
    case 'userVoice':
      return <UserVoice />
    case 'userSetting':
      return <UserSetting />
    case 'edit':
      return <Edit />
    case 'download':
      return <Download />
    case 'copyTwo':
      return <CopyTwo />
    case 'delete':
      return <Delete />
    case 'copyThree':
      return <CopyThree />
    case 'addIcon':
      return <AddIcon />
    case 'americanFlag':
      return <AmericanFlag />
    case 'userUnFilled':
      return <UserUnFilled />
    case 'chevronDown':
      return <ChevronDown />
    case 'mangerLogo':
      return <MangerLogo />
    case 'mailBoxOperator':
      return <MailBoxOperator />
    case 'adminLogo':
      return <AdminLogo />
    case 'editIcon':
      return <EditIcon />
    case 'planTitleIcon':
      return <PlanTitleIcon />
    case 'ocheck':
      return <OCheck />
    case 'phoneCall':
      return <PhoneCall />
    case 'microphoneOff':
      return <MicrophoneOff />
    case 'filledCircle':
      return <CircleFilled />
    case 'horizonalDots':
      return <HorizontalDotts />
    case 'reports':
      return <Reports />
    case 'supportChat':
      return <SupportChat />
    case 'logTickets':
      return <LogTickets />
    case 'scanMail':
      return <ScanMail />
    case 'forwardMail':
      return <ForwardMail />
    case 'sherdMail':
      return <SherdMail />
    case 'pickUpMail':
      return <PickUpMail />
    case 'junckMail':
      return <JunckMail />
    case 'depositMail':
      return <DepositMail />
    case 'backIcon':
      return <BackIcon />
    default:
      return ''
  }
}

export default GetLogo
