import React, { useState} from "react";
import { ChatItemContainer } from "./styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdPhotoSizeSelectSmall } from "react-icons/md";

const ChatItem = ({}) => {
  const [isOnline, setIsOnline] = useState(true);
  const TruncateText = ({ text }) => {
    const maxLength = 17;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };
  return (
    <ChatItemContainer>
      <div className="outer-div">
        <div className="left">
          <div className="image-div">
            <img
              src="https://lh3.googleusercontent.com/a-/ALV-UjUNb8EWts7ArSfQ-g4COjtPHx5XB_oxfmngO-3-QApeMZCoNw=s40-w40-h40-c-k-no"
              alt=""
            />

            <div
              style={{
                background: isOnline ? "green" : "gray",
              }}
              className="status-div"
            ></div>
          </div>

          <span className="name">
            <TruncateText text="Asad Muhammad"></TruncateText>
          </span>
        </div>

        <div className="right">
          <span className="icon">
            <MdPhotoSizeSelectSmall color="#2b3746" />
          </span>
          <span className="icon">
            <BsThreeDotsVertical color="#2b3746" />
          </span>
        </div>
      </div>
    </ChatItemContainer>
  );
};

export default ChatItem;
