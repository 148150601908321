// src/features/counterSlice.js
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mailParentPage: '',
  mailDetailsToEdit: '',
  currentPage: '',
  sidebarCollapsed: true,
  mailBoxLocationId: '',
  userFlowSelectedTab: '1',
  companyFlowSelectedTab: '1',
  userMailBoxDetails: '',
  userSelectedLocation: '',
  userSelcetedPipeLine: { name: 'Onboarding', id: 1 },
  isMailCounterChanged: false,
}

export const appSlice = createSlice({
  name: 'AppAuth',
  initialState,
  reducers: {
    handleCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    handleCollapsed: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed
    },
    handMailBoxLocationId: (state, action) => {
      state.mailBoxLocationId = action.payload
    },
    handSelectUserFlowTab: (state, action) => {
      state.userFlowSelectedTab = action.payload
    },
    handSelectCompanyFlowTab: (state, action) => {
      state.companyFlowSelectedTab = action.payload
    },
    handleAddUserMailBoxDetails: (state, action) => {
      state.userMailBoxDetails = action.payload
    },
    handleAddUserelectedLocation: (state, action) => {
      state.userSelectedLocation = action.payload
    },
    handleUserSelcetedPipeLine: (state, action) => {
      state.userSelcetedPipeLine = action.payload
    },
    handleAddMailDetailsToEdit: (state, action) => {
      state.mailDetailsToEdit = action.payload
    },
    handleAddMailParentPageToEdit: (state, action) => {
      state.mailParentPage = action.payload
    },
    handleRefetchMailCounter: (state, action) => {
      state.isMailCounterChanged = action.payload
    },
  },
})

export const {
  handleCurrentPage,
  handleCollapsed,
  handMailBoxLocationId,
  handSelectUserFlowTab,
  handSelectCompanyFlowTab,
  handleAddUserMailBoxDetails,
  handleAddUserelectedLocation,
  handleUserSelcetedPipeLine,
  handleAddMailDetailsToEdit,
  handleAddMailParentPageToEdit,
  handleRefetchMailCounter,
} = appSlice.actions

export default appSlice.reducer
